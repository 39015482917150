import React, { useState } from "react";
import InfoPanel from "../../components/InfoPanel";
import MainDatasetDisplay from "../routes/pages/MainDatasetDisplay";
import AccordianNav from "../../components/AccordianNav";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { faTable } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
/**
 * A layout that displays the dataset listings, a filter nav, and an info panel that
 * contains a detail summary
 */
function HomeLayout(props) {
  //const [filterMode, setFilterMode] = useState("REGENSTRIEF DATA RESOURCES");

  function handleSwitchFilterMode(filterTag) {
    setFilterMode(filterTag);
  }

  function getLogo() {
    props.onClickDirectDataSourceFilterLink(props.selectedFilterId)
    if (props.selectedFilterId === "IADC")
      return "/images/RI_Data_Icon_IADC.png";
    if (props.selectedFilterId === "REGENSTRIEF DATA RESOURCES")
      return "/images/RI_Data_Icon-Clinical.png";
    if (props.selectedFilterId === "CURATED REGISTRIES")
      return "/images/RI_Data_Icon-Registry.png";

    return "";
  }

  function getDescText() {
    if (props.selectedFilterId === "IADC")
      return "The IADC is a specialized resource focused on lowering the barriers to accessing meaningful data used to fight the addictions crisis in Indiana.  The IADC is composed of a collection of data resources and data partners, but also a dedicated team who will work with data requestors to: <ol><li> Develop unique, integrated data sets from available data resources</li> <li> Supplement researcher-collected or existing data sets with data from IADC </li><li> Help establish access or define pathways to access alternative data sources not currently represented in the IADC data catalogue </li><br/> IADC data resources are organized into two separate categories, centralized data resources and non-centralized data resources.  Centralized data resources are data resources that the Regenstrief Institute maintains direct access to (I.e., INPC, IU Health and Eskenazi Health data warehouses and others).  Non-centralized data resources are data resources governed and maintained by partner organizations but made accessible to the IADC data requestors with the appropriate data governance in place.  Non-centralized data resources would include requests for data sets obtained through the Polis Center or Management Performance Hub.  Please note that with the appropriate governance in place, the IADC can enable person level and geographic level linkage to non-centralized data sources.  Requests to perform this function can be initiated through the IADC request process.   The IADC is an evolving data resource, which is actively working to enable data access to prioritized data resources necessary to fight the addictions crisis in Indiana.  Metadata describing available data resources which can be accessed through the IADC will be updated on a regular basis, as new resources are identified. ";
    if (props.selectedFilterId === "CURATED REGISTRIES")
      return "Condition-specific data registries combine data from EHRs and the health information exchange, maintained as research-ready data sets. Evidence generated from registries like these can streamline translational research and speed implementation by narrowing the scope of what does and does not work in clinical settings. ";
    if (props.selectedFilterId === "REGENSTRIEF DATA RESOURCES")
      return "The Regenstrief Institute enables access to 3 clinical data repositories which store highly granular patient level data.  These repositories include the Indiana Network for Patient Care (Indiana’s statewide health information exchange), the IU Health Enterprise Data Warehouse, and the Eskenazi Health Data Warehouse.  Collaborators can work directly with the Regenstrief Data Services team to develop and extract electronic health records data which meet their needs.  To explore more detailed information in these data resources as well as others click the link below.";

    return "";
  }

  function getExploreText() {
    if (props.selectedFilterId === "IADC")
      return "Explore the data sets available through the IADC below.";
    if (props.selectedFilterId === "CURATED REGISTRIES")
      return "Explore the data sets available through the CURATED REGISTRIES below.";
    if (props.selectedFilterId === "REGENSTRIEF DATA RESOURCES")
      return "Explore the data sets available through the REGENSTRIEF DATA RESOURCES below.";

    return "";
  }

  function getFundingLogo() {
    if (props.selectedFilterId === "IADC")
      return "/images/IU-GC-logo-iadc-2019.png";
    if (props.selectedFilterId === "CURATED REGISTRIES") return "";
    if (props.selectedFilterId === "REGENSTRIEF DATA RESOURCES") return "";

    return "";
  }

  return (
    <main>
      <Container fluid="true" className="containerStyles">
        <Row>
          <div className="faceSheetTab">
            <ul className="menu">
              <li>
                <Link to="/iadc/catalog/datasets/rds">
                  <span
                    id="REGENSTRIEF DATA RESOURCES"
                    className={
                      props.selectedFilterId == "REGENSTRIEF DATA RESOURCES"
                        ? "selectedLiTextStyles"
                        : "liTextStyles"
                    }
                    onClick={props.onClickDataSourceFilter}
                  >
                    REGENSTRIEF DATA RESOURCES
                  </span>
                </Link>
              </li>
              <li>
                <Link to="/iadc/catalog/datasets/cr">
                  <span
                    id="CURATED REGISTRIES"
                    className={
                      props.selectedFilterId == "CURATED REGISTRIES"
                        ? "selectedLiTextStyles"
                        : "liTextStyles"
                    }
                    onClick={props.onClickDataSourceFilter}
                  >
                    CURATED REGISTRIES
                  </span>
                </Link>
              </li>
              <li>
                <Link to="/iadc/catalog/datasets/iadc">
                  <span
                    id="IADC"
                    className={
                      props.selectedFilterId == "IADC"
                        ? "selectedLiTextStyles"
                        : "liTextStyles"
                    }
                    onClick={props.onClickDataSourceFilter}
                  >
                    IADC
                  </span>
                </Link>
              </li>

              {/* <li>
                <span id="pis" onClick="" className={"liTextStyles"}>
                  PRIMARY INVESTIGATORS
                </span>
              </li>
              <li>
                <span id="contact" onClick="" className={"liTextStyles"}>
                  CONTACT US
                </span>
             </li>*/}
            </ul>
          </div>
        </Row>

        <Row className="datasetsDiv">
          {/*  <Col md={2}>
            <AccordianNav filterCb={handleSwitchFilterMode} />
  </Col>*/}

          <Col>
            <MainDatasetDisplay
              mainTitle="REGENSTRIEF DATA RESOURCES"
              filterMode={props.filterMode}
              handleDataSetClick={props.handleDataSetClick}
              subTitle="Don't see what you are looking for? Contact us if you would like for us to import new data!"
              color="black"
              assetCDN={props.assetCDN}
              logo={getLogo()}
              descText={getDescText()}
              exploreText={getExploreText()}
              fundingLogo={getFundingLogo()}
            />
          </Col>
        </Row>
        {/*<InfoPanel />*/}
      </Container>
    </main>
  );
}

export default HomeLayout;

import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";

//graphql api always returns a json object.
//In our case if the user session times out, graphql api request is redirecting to
//login page as per the security config that we set and is falling under network error in apollo graphql clinet.
//so it is safe to have the redirect logic interceptor here in apollo logout/error link.
const logoutlink = onError(({ graphQLErrors, networkError, response }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
    //uncomment below in case if you would like to redirect the user to login page in case of graphql errors
    //client.writeData({ data: { isLoggedIn: false } });
    //  client.resetStore(); //call client.resetStore to reset on logout redirect
  } else if (
    (networkError && networkError.toString().includes("ServerParseError")) ||
    networkError.toString().includes("Unexpected token < in JSON")
  ) {
    //appcache.writeData({ data: { isLoggedIn: false } });
    client.writeData({ data: { isLoggedIn: false } });
    client.resetStore(); //call client.resetStore to reset on logout redirect
  }
});

const appcache = new InMemoryCache();

appcache.writeData({
  data: {
    user: { __typename: "User", name: "User" },
    infoPanel: {
      __typename: "InfoPanel",
      title: "",
      content: "Mouse over a row to see its highlights",
      links: "",
      granularity: "",
      linkingAttrs: "",
      coverageYears: "",
      geographicCoverage:"",
      datasetType:"",
      updateFrequency:""
    },
    isLoggedIn: true
  }
});

export const client = new ApolloClient({
  cache: appcache,
  link: logoutlink.concat(
    new HttpLink({ uri: "/iadc/graphql", credentials: "include" })
  ),
  //credentials: "include"
  //if you include below resolvers in appcache.writedata, you do not need to have them here again
  resolvers: {
    Query: {
      user: () => ({ __typename: "User", name: "Username" }),
      infoPanel: () => ({
        __typename: "InfoPanel",
        title: "",
        content: "Mouse over a row to see its highlights",
        links: "",
        granularity: "",
        linkingAttrs: "",
        coverageYears: "",
        geographicCoverage:"",
        datasetType:"",
        updateFrequency:""
      }),
      isLoggedIn: true
    }
  }
  /* typeDefs: `
        type Query {
            user: {
                name: String
            }
        }
    `*/
});

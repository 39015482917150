import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCog } from "@fortawesome/free-solid-svg-icons";
import { GET_USERS } from "../configs/queryConfig";
function MainNavBar(props) {
  const { loading, error, data } = useQuery(GET_USERS);

  if (loading) return <p>Loading ...</p>;

  return (
    <nav
      className="navbar navbar-expand-md navbar-light "
      style={{ backgroundColor: "white", height: "90px" }}
    >
      {/*<a className="navbar-brand" href="/iadc/catalog/browse">
        <img
          src={props.assetCDN + "/images/ri.png"}
          style={{ height: "40px" }}
        />
  </a>*/}
      <a className="navbar-brand" href="/iadc/catalog/browse">
        <img
          src={props.assetCDN + "/images/logo_ri_header_dc.png"}
          style={{ height: "60px" }}
        />
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarCollapse">
        <ul className="navbar-nav mr-auto">
          {/* <li className="nav-item active">
                   <Link
                    className="nav-link navitemtextcolor"
                    to="/iadc/catalog/index.html"
                  >
                    Home
        </Link>
                </li>

          <a
            className=" homeBtn fas fa-home  navitemtextcolor"
            href="/iadc/catalog/browse"
          >
            <p>Home</p>
          </a>*/}

          {props.showBackBtn && (
            <a
              className="backBtn fas fa-arrow-circle-left  navitemtextcolor"
              href="/iadc/catalog/browse"
            >
              <p>Back</p>
            </a>
          )}

          {/*<a
            className=" backBtn fas fa-pencil-alt navitemtextcolor"
            href="https://www.regenstrief.org/iadc-tester/"
            target="_blank"
          >
            <p>I want to know more</p>
          </a>*/}

          <li className="nav-item">
            <div id="searchBar"></div>
          </li>
        </ul>
        {/* <ul
          className="nav navbar-nav navbar-right"
          style={{ marginRight: "150px" }}
        >
          <a
            style={{ color: "rgba(0, 0, 0, 0.75)" }}
            href="https://www.regenstrief.org/contact-iadc/"
            target="_blank"
          >
            Submit Data Inquiry
          </a>
          {/*  <a
                  className="logoutBtn fas fa-sign-out-alt nav-link navitemtextcolor"
                  href="/iadc/dblogin/logout"
                >
                  <p>Logout</p>
                </a>
        </ul>*/}
        {/*
        <ul
          className="nav navbar-nav navbar-right"
          style={{ marginRight: "150px", fontSize: "14px" }}
        >
          <li className="nav-item">
            <span className="navitemtextcolor">Hello {data.user.name}</span>
            <span className="navitemtextcolor" text="username"></span>
          </li>
          <li style={{ marginLeft: "5px" }} className="dropdown">
            <a
              href="#"
              data-toggle="dropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <FontAwesomeIcon
                icon={faUserCog}
                size="lg"
                pull="left"
                color="white"
              />
            </a>
            <ul className="dropdown-menu">
              <li className="nav-item">
                <a className="nav-link" href="#">
                  User Profile
                </a>
              </li>
              {/*} <li className="nav-item">
                      <div>
                        <a className="nav-link" href="#">
                          Change Password
                        </a>
                      </div>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/iadc/dblogin/logout">
                  Logout
                </a>
              </li>
            </ul>
          </li>
        </ul>*/}
      </div>
    </nav>
  );
}

export default MainNavBar;

import React, { useState, useEffect } from "react";
import InfoPanel from "../../components/InfoPanel";
import MainDatasetDisplay from "../routes/pages/MainDatasetDisplay";
import AccordianNav from "../../components/AccordianNav";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";

import Button from "react-bootstrap/esm/Button";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { GET_DATASET_DETAILS } from "../configs/queryConfig";
import Carousel from "react-multi-carousel";

//import "react-multi-carousel/lib/styles.scss";
import { faTable } from "@fortawesome/free-solid-svg-icons";
/**
 * A layout that displays the dataset listings, a filter nav, and an info panel that
 * contains a detail summary
 */
function HomeLayoutV1(props) {
  useEffect(() => {
    props.onLoadDataSourceFilter();
  });
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const arrowStyle = {
    background: "transparent",
    border: 0,
    color: "black",
    fontSize: "30px",
    paddingLeft: "30px",
    paddingRight: "30px",
  };
  const CustomRight = ({ onClick }) => (
    <button className="arrow right" onClick={onClick} style={arrowStyle}>
      <FontAwesomeIcon icon={faAngleRight} size="lg" />
    </button>
  );
  const CustomLeft = ({ onClick }) => (
    <button className="arrow left" onClick={onClick} style={arrowStyle}>
      <FontAwesomeIcon icon={faAngleLeft} size="lg" />
    </button>
  );

  function handleSwitchFilterMode(filterTag) {
    setFilterMode(filterTag);
  }

  const tagName = props.filterMode;
  const { loading, error, data, client } = useQuery(GET_DATASET_DETAILS, {
    variables: { tagName: tagName },
  });

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;
  var results = data.getFilteredDatasets;

  return (
    <main>
      <Container fluid="true" className="containerStyles">
        <Row>
          <div className="faceSheetTab">
            <ul className="menu">
              <li>
                <Link to="/iadc/catalog/datasets/rds">
                  <span
                    id="REGENSTRIEF DATA RESOURCES"
                    className={
                      props.selectedFilterId == "REGENSTRIEF DATA RESOURCES"
                        ? "selectedLiTextStyles"
                        : "liTextStyles"
                    }
                    onClick={props.onClickDataSourceFilter}
                  >
                    REGENSTRIEF DATA RESOURCES
                  </span>
                </Link>
              </li>
              <li>
                <Link to="/iadc/catalog/datasets/cr">
                  <span
                    id="CURATED REGISTRIES"
                    className={
                      props.selectedFilterId == "CURATED REGISTRIES"
                        ? "selectedLiTextStyles"
                        : "liTextStyles"
                    }
                    onClick={props.onClickDataSourceFilter}
                  >
                    CURATED REGISTRIES
                  </span>
                </Link>
              </li>
              <li>
                <Link to="/iadc/catalog/datasets/iadc">
                  <span
                    id="IADC"
                    className={
                      props.selectedFilterId == "IADC"
                        ? "selectedLiTextStyles"
                        : "liTextStyles"
                    }
                    onClick={props.onClickDataSourceFilter}
                  >
                    IADC
                  </span>
                </Link>
              </li>
              {/* <li>
                <span id="pis" onClick="" className={"liTextStyles"}>
                  PRIMARY INVESTIGATORS
                </span>
              </li>
              <li>
                <span id="contact" onClick="" className={"liTextStyles"}>
                  CONTACT US
                </span>
             </li>*/}
            </ul>
          </div>
        </Row>
        <Row className="datasetsDiv">
          {/*  <Carousel responsive={responsive}>
            {results.map((item, index) => {
              return (
                <div key={index} className={"datasetGridDiv"}>
                  <div>
                    <div className="datasetGridDivTitle">
                      <strong>
                        <p style={{ fontSize: "18px" }}>
                          {item.datasourceTitle.split("(")[0]}
                        </p>
                      </strong>
                    </div>
                    <div className="datasetGridDivDesc">
                      <p>{item.description}</p>
                    </div>
                    <div>
                      <Link
                        className="d-block lead text-dark"
                        to={{
                          pathname:
                            "showDataset/" +
                            props.filterMode +
                            "/" +
                            item.pk_id,
                          state: {
                            requestedDataset: item.datasetResourceId,
                            id: item.pk_id,
                            filterMode: props.filterMode,
                          },
                        }}
                        onClick={props.handleDataSetClick}
                      >
                        <Button className="datasetGridDivBtn">
                          {" "}
                          LEARN MORE
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </Carousel>

          <Col md={2}>
            <AccordianNav filterCb={handleSwitchFilterMode} />
  </Col>

          <Col>
            <MainDatasetDisplay
              mainTitle="Available Datasets"
              filterMode={filterMode}
              handleDataSetClick={props.handleDataSetClick}
              subTitle="Don't see what you are looking for? Contact us if you would like for us to import new data!"
              color="black"

              //icon={faTable}
            />
          </Col>*/}

          <Carousel
            ssr
            partialVisbile
            deviceType="desktop"
            itemClass="image-item"
            responsive={responsive}
            customRightArrow={<CustomRight />}
            customLeftArrow={<CustomLeft />}
          >
            {results.map((item, index) => {
              return (
                <div key={index} className={"datasetGridDiv"}>
                  <div>
                    <div className="datasetGridDivTitle">
                      <strong>
                        <p style={{ fontSize: "18px" }}>
                          {item.datasourceTitle.split("(")[0]}
                        </p>
                      </strong>
                    </div>
                    <div className="datasetGridDivDesc">
                      <p>{item.description}</p>
                    </div>
                    <div>
                      <Link
                        className="d-block lead text-dark"
                        to={{
                          pathname:
                            "showDataset/" +
                            props.filterMode +
                            "/" +
                            item.pk_id,
                          state: {
                            requestedDataset: item.datasetResourceId,
                            id: item.pk_id,
                            filterMode: props.filterMode,
                          },
                        }}
                        onClick={props.handleDataSetClick}
                      >
                        <Button className="datasetGridDivBtn">
                          {" "}
                          LEARN MORE
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </Carousel>

          <div
            style={{
              textAlign: "center",

              width: "100%",
              display: "inline-flex",
              //padding: "30px",
              margin: "10px",
            }}
          >
            <div>
              <img src={props.assetCDN + "/images/RI_Data_Icon_IADC.png"} />
            </div>
            <div style={{ width: "60%", marginTop: "60px" }}>
              <h2>
                How the IADC Harmonizes Statewide Data on Opioid Addiction
              </h2>
              <p>
                To combat the opioid crisis, researchers and health care
                professionals need access to information across several
                different domains. The Indiana Addictions Data Commons (IADC)
                was developed to enable access to data across several partner
                organizations and public domains. With the support of our team
                the IADC has the unique capability to link data across disparate
                data resources and work with requestors to develop a dataset
                that meets their needs. To explore some of the available data
                resources and partner organizations, please click the link
                below.
              </p>
              <Link className="linkStyles" to="/iadc/catalog/datasets/iadc">
                <span
                  id="IADC"
                  className="datasetDescDivBtn"
                  onClick={props.onClickDataSourceFilter}
                >
                  Explore
                </span>
              </Link>
            </div>
          </div>
          <div
            style={{
              display: "inline-flex",
              width: "100%",
              margin: "10px",
            }}
          >
            <div
              style={{
                float: "left",
                width: "50%",
              }}
            >
              <h2 style={{ marginLeft: "40px" }}>
                Explore curated data registries
              </h2>
              <div style={{ display: "inline-flex" }}>
                <img
                  src={props.assetCDN + "/images/RI_Data_Icon-Registry.png"}
                  style={{ height: "250px" }}
                />
                <div>
                  <p style={{ marginTop: "50px" }}>
                    Condition-specific data registries combine data from EHRs
                    and the health information exchange, maintained as
                    research-ready data sets. Evidence generated from registries
                    like these can streamline translational research and speed
                    implementation by narrowing the scope of what does and does
                    not work in clinical settings.
                  </p>
                  <Link className="linkStyles" to="/iadc/catalog/datasets/cr">
                    <span
                      id="CURATED REGISTRIES"
                      className="datasetDescDivBtn"
                      onClick={props.onClickDataSourceFilter}
                    >
                      Explore
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <div
              style={{
                float: "left",
                width: "50%",
              }}
            >
              <h2 style={{ marginLeft: "40px" }}>
                Explore Regenstrief clinical data resources
              </h2>
              <div style={{ display: "inline-flex" }}>
                <img
                  src={props.assetCDN + "/images/RI_Data_Icon-Clinical.png"}
                  style={{ height: "250px" }}
                />
                <div>
                  <p style={{ marginTop: "50px" }}>
                    The Regenstrief Institute enables access to 3 clinical data
                    repositories which store highly granular patient level data.
                    These repositories include the Indiana Network for Patient
                    Care (Indiana’s statewide health information exchange), the
                    IU Health Enterprise Data Warehouse, and the Eskenazi Health
                    Data Warehouse. Collaborators can work directly with the
                    Regenstrief Data Services team to develop and extract
                    electronic health records data which meet their needs. To
                    explore more detailed information in these data resources as
                    well as others click the link below.
                  </p>
                  <Link className="linkStyles" to="/iadc/catalog/datasets/rds">
                    <span
                      id="REGENSTRIEF DATA RESOURCES"
                      className="datasetDescDivBtn"
                      onClick={props.onClickDataSourceFilter}
                    >
                      Explore
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Row>

        {/*<InfoPanel />*/}
      </Container>
    </main>
  );
}

export default HomeLayoutV1;

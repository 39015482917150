import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import "./mainDatasetDisplayStyle.scss";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTable,
  faEdit,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";
import DataRow from "./DataRow";

import { GET_DATASET_DETAILS } from "../../../configs/queryConfig";
import Button from "react-bootstrap/esm/Button";
/**
 * This is what displays the main listing of all availble datasets that
 * are in the catalog
 */
function MainDatasetDisplay(props) {
  /*test(client) {
    client.query({ query: GET_INFOPANEL }).then(data => console.log(data));
  }*/

  /**
   * This is a callback for when a user hovers over a row
   */
  function onRowHover(isToggled) {
    //console.log("Hover is " + isToggled);
  }
//Notes on 02/02/2023: Earlier urls are different for each data source. With this change all datasources are using same url.
//I think this is not a frequent update. Ow it is better to have this as a property so that we can just update the properties file
//and a server restart. This avoids code changes and deployment process.
  function getDataInquiryURL() {
    if (props.filterMode === "IADC") {
      return "https://www.regenstrief.org/data-request/";
      //"https://www.regenstrief.org/contact-iadc/";
    } else {
      return "https://www.regenstrief.org/data-request/";
    }
    //return "https://www.regenstrief.org/data-request/";
  }

  function SegmentBox(props) {
    return (
      <div className="my-3 p-3 bg-white rounded box-shadow">
        {/* <FontAwesomeIcon icon={faEdit} size="lg" pull="left" />*/}
        <h5 className="border-bottom border-gray pb-2 mb-0">{props.title}</h5>
        {props.children}
      </div>
    );
  }

  function diagnosticInfoTemplate(data, error) {
    const { bodyText, name, message } = error.networkError;
    return (
      <div>
        <p>
          <strong>Web Browser Used:</strong>
        </p>
        <p>{navigator.userAgent}</p>
        <pre>
          Data Errors{" "}
          {error.graphQLErrors.map(({ message }, i) => (
            <span key={i}>{message}</span>
          ))}
        </pre>
        <pre>
          Network Errors (Response Received): Name: {name}
          Mmessage: {message}
        </pre>
        <p>
          <strong>Data Recieved: (It should be JSON)</strong>
        </p>
        <pre>{bodyText ? bodyText : data}</pre>
      </div>
    );
  }

  const tagName = props.filterMode;
  const { loading, error, data, client } = useQuery(GET_DATASET_DETAILS, {
    variables: { tagName: tagName },
  });

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;
  var results = data.getFilteredDatasets;

  return (
    <div className="datasetMain">
      <div
        style={{
          display: "inline-flex",
          marginRight: "50px",
          marginTop: "10px",
        }}
      >
        <div>
          {props.logo != "" && (
            <img
              src={props.assetCDN + props.logo}
              style={{ height: "250px" }}
            />
          )}
        </div>
        <div>
          {props.descText != "" && (
            <p dangerouslySetInnerHTML={{ __html: props.descText }}></p>
          )}
          <div
            style={{
              display: "inline-flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div>
              <p>
                {props.exploreText != "" && (
                  <strong style={{ color: "black" }}>
                    {props.exploreText}
                  </strong>
                )}
              </p>
              {props.exploreText != "" && (
                <a href={getDataInquiryURL()} target="_blank">
                  <span className="datasetGridDivBtn btn btn-primary">
                    Submit Data Inquiry
                  </span>
                </a>
              )}
            </div>
            {props.fundingLogo != "" && (
              <div>
                <span style={{ paddingLeft: "10px" }}>Supported by </span>
                <br />
                <img
                  src={props.assetCDN + props.fundingLogo}
                  style={{ height: "75px" }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div style={{ marginLeft: "20px" }}>
        {results.map((item, index) => {
          return (
            <div key={index} className={"datasetGridDiv"}>
              <div>
                <div className="datasetGridDivTitle">
                  <strong>
                    <p style={{ fontSize: "18px" }}>
                      {item.datasourceTitle.split("(")[0]}
                    </p>
                  </strong>
                </div>
                <div className="datasetGridDivDesc">
                  <p>{item.description}</p>
                </div>
                <div>
                  <Link
                    className="d-block lead text-dark"
                    to={{
                      pathname:
                        "/iadc/catalog/showDataset/" + props.filterMode + "/" + item.pk_id,
                      state: {
                        requestedDataset: item.datasetResourceId,
                        id: item.pk_id,
                        filterMode: props.filterMode,
                      },
                    }}
                    onClick={props.handleDataSetClick}
                  >
                    <Button className="datasetGridDivBtn"> LEARN MORE</Button>
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {/* <div className="d-flex align-items-center p-3 text-white-50 bg-purple rounded box-shadow">
        <FontAwesomeIcon icon={props.icon} size="5x" pull="left" />
        <div className="lh-100">
          <h4 className="mb-0 text-white lh-100">{props.mainTitle}</h4>
          <small>{props.subTitle}</small>
        </div>
      </div>
      <SegmentBox title={props.filterMode}>
        {results.map((item, index) => (
          <div
            key={index}
            onMouseEnter={target =>
              client.writeData({
                data: {
                  infoPanel: {
                    __typename: "InfoPanel",
                    title: item.datasourceTitle,
                    content: item.description,
                    links: item.links,
                    granularity: item.granularity,
                    linkingAttrs: item.linkingAttrs,
                    coverageYears: item.coverageYears
                    //you can write whole item if you don't know the individual attributes
                    // ...item
                  }
                }
              })
            }
          >
            <DataRow
              key={item.pk_id}
              rowId={item.pk_id}
              resourceId={item.datasetResourceId}
              title={item.datasourceTitle}
              description={item.description}
              onRowClick={onRowHover}
              handleDataSetClick={props.handleDataSetClick}
              filterMode={props.filterMode}
            />
          </div>
        ))}
          </SegmentBox>*/}
    </div>
  );
}
//content: item.description, granularity:item.granularity
export default MainDatasetDisplay;

MainDatasetDisplay.propTypes = {
  mainTitle: PropTypes.string,
  subTitle: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.object,
};

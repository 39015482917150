import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DatasetViewTable from "./DatasetViewTable";
import Card from "react-bootstrap/Card";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";

function DatasetDetailPage(props) {
  const [viewMode, setViewMode] = useState("Dataset Structure");
  const [currentButtonMode, setCurrentButtonMode] = useState([
    // { name: "Dataset Structure", isSelected: true },
    // { name: "Domain", isSelected: false }
  ]);
  const [viewAll, setViewAll] = useState(false);

  function handleModeSelectionClick(event, name) {
    event.preventDefault();
    let newMode = currentButtonMode.map((item, index) => ({
      name: item.name,
      isSelected: name === item.name,
    }));
    setViewMode(name);
    setCurrentButtonMode(newMode);
  }

  function onClickViewAll() {
    if (viewAll) setViewAll(false);
    else setViewAll(true);
  }

  if (props.datasetCnt > 0) {
    return (
      <Card>
        {/*<Card.Header>
        <ButtonGroup>
          {currentButtonMode.map((item, index) => (
            <Button
              key={index}
              onClick={(event) => handleModeSelectionClick(event, item.name)}
              variant={item.isSelected ? "primary" : "secondary"}
              size="sm"
            >
              {item.name}
            </Button>
          ))}
        </ButtonGroup>
          </Card.Header>*/}
        <Card.Body>
          <div
            style={{
              display: "inline-flex",
              height: "30px",
              marginBottom: "10px",
            }}
          >
            <Card.Title>Dataset Structure</Card.Title>
            <Button
              size="sm"
              style={{
                marginLeft: "30px",
                backgroundColor: "#24afdf",
                borderColor: "#24afdf",
              }}
              onClick={onClickViewAll}
            >
              {viewAll ? "Paging" : "View All"}
            </Button>
          </div>
          <DatasetViewTable
            datasetId={props.datasetId}
            viewMode={viewMode}
            viewAll={viewAll}
          />
        </Card.Body>
      </Card>
    );
  } else {
    return <div></div>;
  }
}
export default DatasetDetailPage;

// DatasetDetailPage.propTypes = {
//     mainTitle: PropTypes.string,
//     subTitle: PropTypes.string,
//     color: PropTypes.string,
//     icon: PropTypes.object
// };

import gql from "graphql-tag";

export const GET_DATASET_DESCRIPTION = gql(`
query getDatasetDescription($id : Long!) {
    getDatasetDescription(id : $id) {  
    pk_id
    datasourceTitle
    links
    description
    granularity
    linkingAttrs
    coverageYears
    datasetKeys
    geographicCoverage
    datasetType
    updateFrequency
    }

     getDatasetViewStructure(id : $id) {
      name
      descriptionData
  }
}

  `);

export const GET_USERS = gql(`
    query  getUsers {
      user @client {
        name
      }
    }
  `);

export const GET_DATASET_ALL_ATTRS = gql(`
query getDescriptionTable($id : Long!) {
    getDatasetViewStructure(id : $id) {
        name
        descriptionData
    }
    getDomainIdsForDataset(id : $id){
        domainIds
    }
}

  `);

export const GET_DATASET_DETAILS = gql(`
query getFilteredDatasets($tagName : String!) {  
    getFilteredDatasets(tagName : $tagName) {  
        pk_id
        datasourceTitle
        datasetResourceId
        description
        links
        granularity
        linkingAttrs
        coverageYears
        geographicCoverage
        datasetType
        updateFrequency
        datasetDetailResults {  
            domainIds
        }
        
    }
}
  `);

export const GET_INFOPANEL = gql(`
  query  getInfo {
    infoPanel @client {
      content
      links
      granularity
      linkingAttrs
      coverageYears
      geographicCoverage
      datasetType
      updateFrequency
    }
  }
`);

export const IS_USER_LOGGED_IN = gql(`
query  isUserLoggedIn {
    isLoggedIn @client 
}
`);

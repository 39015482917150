import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import "./accordianNavStyles.scss";

function AccordianNav(props) {
  function filterClicked(filterName) {
    // Use the callback from the HomeLayout
    props.filterCb(filterName);
  }

  return (
    <div className="stickyAccordion">
      <Accordion defaultActiveKey="0">
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="0">
              All Datasets
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <ListGroup variant="flush">
                <ListGroup.Item
                  action
                  onClick={() => filterClicked("Available Datasets")}
                >
                  All
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  onClick={() => filterClicked("Secure Dataset")}
                >
                  Secured Datasets
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  onClick={() => filterClicked("Public Dataset")}
                >
                  Public Datasets
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="1">
              Domains
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              <ListGroup variant="flush">
                <ListGroup.Item
                  action
                  onClick={() => filterClicked("Clinical")}
                >
                  Clinical
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  onClick={() => filterClicked("Social Determinants")}
                >
                  Social Determinants
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  onClick={() => filterClicked("Education and Workforce")}
                >
                  Education and Workforce
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="2">
              Granularity
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="2">
            <Card.Body>
              <ListGroup variant="flush">
                <ListGroup.Item
                  action
                  onClick={() => filterClicked("Person Level")}
                >
                  Person Level
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  onClick={() => filterClicked("Geographic Level")}
                >
                  Geographic Level
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="3">
              Stewardship
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="3">
            <Card.Body>
              <ListGroup variant="flush">
                <ListGroup.Item
                  action
                  onClick={() => filterClicked("Regenstrief Institute")}
                >
                  Regenstrief Institute
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  onClick={() =>
                    filterClicked("Indiana Management Performance Hub")
                  }
                >
                  Management Performance Hub
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  onClick={() => filterClicked("Drug Enforcement Agency")}
                >
                  Drug Enforcement Agency
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  onClick={() => filterClicked("US Department of Agriculture")}
                >
                  US Department of Agriculture
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
}

export default AccordianNav;

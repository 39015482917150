import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import PropTypes from "prop-types";

import ReactTable from "react-table";
// This uses custom CSS
import "react-table/react-table.css";
import "./datasetDetailPageStyles.scss";
import _ from "lodash";
import { GET_DATASET_ALL_ATTRS } from "../../../configs/queryConfig";
// View Modes
const STRUCTURE = "STRUCTURE";
const DOMAIN = "DOMAIN";
/**
 * Displays the table in the DatasetView page. It attempts to map the
 * graphql results to a table using 'accessor' identifiers
 */

function DatasetViewTable(props) {
  const [viewMode, setViewMode] = useState(props.viewMode);

  /**
   * Produces data into a structure like
   * {
   *       name: 'GEOID',
   *       vocab_ids : 'Geographic Area',
   *       super_domain : 'Social Determinants of Health'
   *  }
   * @param {} data - The data thats returned from the graphql
   */
  function prepDataForStructureView(data) {
    const columns = [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Vocabulary Id",
        accessor: "vocab_ids",
      },
      {
        Header: "Super Domain",
        accessor: "super_domain",
      },
    ];

    let { rows } = data;
    let rows2obj = rows.map((eachArray) => ({
      name: eachArray[0],
      vocab_ids: eachArray[1],
      super_domain: eachArray[2],
    }));

    return { columns: columns, rowData: rows2obj };
  }

  function newPrepDataForStructureView(data) {
    if (!Array.isArray(data) || !data.length)
      return { columns: [], rowData: [] };
    // This is a sample so that the structure can be analyzed
    let sample = _.head(data);
    let s = structureizeRow(sample);
    let keys = _.keys(s);

    //Default columns that we want to show in the UI and should be in the below order
    //Sometimes we may not have data for all columns in a selected  dataset
    //In that case we need to remove those column headers alone (that doesn't have data) from the columns variable
    const columns = [
      {
        Header: "Table Name",
        accessor: "TABLE_NAME",
      },
      {
        Header: "Column Name",
        accessor: "COLUMN_NAME",
      },
      {
        Header: "Variable Name",
        accessor: "name",
      },
      {
        Header: "Units",
        accessor: "UNITS",
      },
      {
        Header: "Description",
        accessor: "Description",
      },
      {
        Header: "Notes",
        accessor: "Notes",
      },
    ];

    //these are the column headers  from the database

    let columns1 = keys.map((eachKey) => ({
      Header:
        eachKey === "name" ? "Variable Name" : _.startCase(_.toLower(eachKey)),
      accessor: eachKey,
    }));

    //loop through columns and columns1 and make sure to pull only the common ones by skipping the
    //columns that doesn't have data for a selected data set.
    var cols = [];
    for (var i = 0; i < columns.length; i++) {
      var flag = false;
      for (var k = 0; k < keys.length; k++) {
        if (columns[i].Header === columns1[k].Header) {
          flag = true;
          columns[i].Header = columns1[k].Header;
          columns[i].accessor = columns1[k].accessor;
        }
      }
      if (flag) {
        cols.push(columns[i]);
      }
    }

    let rows = data.map((eachObj) => structureizeRow(eachObj));
    return { columns: cols, rowData: rows };
  }

  function structureizeRow(rowSample) {
    let { name, descriptionData } = rowSample;
    return _.merge({ name: name }, JSON.parse(descriptionData));
  }

  /**
   *
   * @param {*} data - The array of data as expressed in a domain id per row
   */
  function prepDataForDomainView(data) {
    const d = data.domainIds;
    const columns = [
      {
        Header: "Domain",
        id: "domainIds",
        accessor: (domain) => domain,
      },
    ];
    return { columns: columns, rowData: d };
  }

  /**
   * Handles which data view to display depnding on the view mode prop
   *
   * @param {} data - The data structure that is returned by the graphql query GET_DATASET_ALL_ATTRS
   */
  function getData(data) {
    switch (props.viewMode) {
      case "Domain":
        return prepDataForDomainView(data.getDomainIdsForDataset);
      case "Dataset Structure":
        return newPrepDataForStructureView(data.getDatasetViewStructure);
      default:
        return { columns: [], rowData: [] };
    }
  }

  const id = props.datasetId;
  const { loading, error, data, client } = useQuery(GET_DATASET_ALL_ATTRS, {
    variables: { id: id },
  });

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;
  const { columns, rowData } = getData(data);

  return (
    <ReactTable
      data={rowData}
      SubComponent={(row) => {
        if (props.viewMode == "Dataset Structure") {
          return (
            <div className="tblSubCompStyles">
              {Object.entries(row.original).map(([key, value]) => {
                return (
                  <div key={key}>
                    {_.startCase(_.toLower(key))} : {value.toString()}
                  </div>
                );
              })}
            </div>
          );
        } else {
        }
      }}
      columns={columns}
      showPaginationTop={props.viewAll ? false : true}
      showPaginationBottom={props.viewAll ? false : true}
      pageSize={props.viewAll ? rowData.length : 50}
      noDataText="No data available for this view"
      //resizable={false}
    />
  );
}
export default DatasetViewTable;

DatasetViewTable.propTypes = {
  datasetId: PropTypes.number,
  viewMode: PropTypes.string,
};

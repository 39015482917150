import React, { useEffect } from "react";
import DetailNavigatorPanel from "../routes/pages/DatasetDetailPage/DetailNavigatorPanel";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTable } from "@fortawesome/free-solid-svg-icons";
import DatasetDetailPage from "../routes/pages/DatasetDetailPage";
import { Link } from "react-router-dom";
import { GET_DATASET_DESCRIPTION } from "../configs/queryConfig";
import { useQuery } from "@apollo/react-hooks";

import "./layoutStyles.scss";

/**
 * Shows details for the individual dataset
 *
 * React-Router will send over a prop that contains the link request
 * in order to find the dataset to display
 */
function DetailLayout(props) {
  useEffect(() => {

    window.scroll(0, 0);
  }, []);

  //data.getDatasetDescription.datasetKeys
  // From react router - vars to determine which page to show
  // If this is an internal react router link, it will have a state var. If its url is directly entered, it will have a prop
  // match variable called id
  const { id, filterMode } = props.location.state
    ? props.location.state
    : props.match.params;
  // match.params.id
  const detailPanelDefault = {
    granularity: "",
    linkingAttrs: "",
    coverageYears: "",
  };
  //const tid = 2;

  const { loading, error, data, client } = useQuery(GET_DATASET_DESCRIPTION, {
    variables: { id: id },
  });

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  return (
    <Container fluid="true" className="detailLayoutcontainerStyles">
      <Row>
        <div className="faceSheetTab">
          <ul className="menu">
            <li>
              <Link to="/iadc/catalog/datasets/rds">
                <span
                  id="REGENSTRIEF DATA RESOURCES"
                  className={
                    props.selectedFilterId == "REGENSTRIEF DATA RESOURCES"
                      ? "selectedLiTextStyles"
                      : "liTextStyles"
                  }
                  onClick={props.onClickDataSourceFilter}
                >
                  REGENSTRIEF DATA RESOURCES
                </span>
              </Link>
            </li>
            <li>
              <Link to="/iadc/catalog/datasets/cr">
                <span
                  id="CURATED REGISTRIES"
                  className={
                    props.selectedFilterId == "CURATED REGISTRIES"
                      ? "selectedLiTextStyles"
                      : "liTextStyles"
                  }
                  onClick={props.onClickDataSourceFilter}
                >
                  CURATED REGISTRIES
                </span>
              </Link>
            </li>
            <li>
              <Link to="/iadc/catalog/datasets/iadc">
                <span
                  id="IADC"
                  className={
                    props.selectedFilterId == "IADC"
                      ? "selectedLiTextStyles"
                      : "liTextStyles"
                  }
                  onClick={props.onClickDataSourceFilter}
                >
                  IADC
                </span>
              </Link>
            </li>
            {/* <li>
                <span id="pis" onClick="" className={"liTextStyles"}>
                  PRIMARY INVESTIGATORS
                </span>
              </li>
              <li>
                <span id="contact" onClick="" className={"liTextStyles"}>
                  CONTACT US
                </span>
             </li>*/}
          </ul>
        </div>
      </Row>
      <Row className="breadcrumbStyles">
        <Col>
          {/*} <Breadcrumb>
                  <Breadcrumb.Item href="catalog/browse">
                    {filterMode}
                  </Breadcrumb.Item>

                  <Breadcrumb.Item active>
                    {" "}
                    {data.getDatasetDescription.datasourceTitle}
                  </Breadcrumb.Item>
        </Breadcrumb>*/}
          <nav aria-label="breadcrumb">
            <ol className="customBreadcrumb">
              <li className="breadcrumb-item">
              {props.filterMode=="IADC"?
                <Link to="/iadc/catalog/datasets/iadc">{props.filterMode}</Link>:
                props.filterMode=="CURATED REGISTRIES"?
                <Link to="/iadc/catalog/datasets/cr">{props.filterMode}</Link>:
                props.filterMode=="ALL"?
                <Link to="/iadc/catalog/datasets/all">{props.filterMode}</Link>:
                <Link to="/iadc/catalog/datasets/rds">{props.filterMode}</Link>
                }
              </li>

              <li className="breadcrumb-item active" aria-current="page">
                {data.getDatasetDescription.datasourceTitle.split("(")[0]}
              </li>
            </ol>
          </nav>
        </Col>
      </Row>
      <Row style={{ marginLeft: "2px", marginRight: "4px" }}>
        <Col>
          <div className="d-flex align-items-center p-3 text-white-50 bg-detail rounded box-shadow">
            <div className="lh-100">
              <h4 className="mb-0 text-white lh-100">
                {data.getDatasetDescription.datasourceTitle}
              </h4>
              <small>
                {loading ? (
                  ""
                ) : data.getDatasetViewStructure.length > 0 ? (
                  data.getDatasetDescription.description
                ) : (
                  <span>
                    {data.getDatasetDescription.description}
                    <br></br>
                    <span style={{ color: "yellow", fontSize: "15px" }}>
                      <strong>(Data Dictionary Currently Unavailable)</strong>
                    </span>
                  </span>
                )}
              </small>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="datasetDetailPage">
        <Col md={9}>
          <DatasetDetailPage
            datasetId={id}
            datasetCnt={data.getDatasetViewStructure.length}
          />
        </Col>
        <Col md={3}>
          <DetailNavigatorPanel
            assetCDN={props.assetCDN}
            dataAttributes={data.getDatasetDescription.datasetKeys}
            {...data.getDatasetDescription}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default DetailLayout;

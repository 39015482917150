import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faHistory,
  faLink,
  faWarehouse,
} from "@fortawesome/free-solid-svg-icons";

/**
 * Displays the panel that contain navigation highlights for this datset
 *
 */
function DetailNavigatorPanel(props) {
  var links = props.links;
  var linksAry = links != null ? links.split(";") : [];

  return (
    <div className="infoPanel">
      <div className="infoPanelheader">
        <p>
          <strong>Dataset Highlights</strong>
        </p>
      </div>
      <div className="infoPanelcontent">
        <div>
          <dt style={{ marginTop: "20px", marginLeft: "30px" }}>
            <img
              src={props.assetCDN + "/images/GranularityIcon-01.png"}
              style={{ height: "40px", marginRight: "10px" }}
            />
            <strong style={{ fontSize: "20px" }}>Level of Detail</strong>
          </dt>
          <p
            style={{
              marginTop: "20px",
              marginLeft: "30px",
              overflowWrap: "anywhere",
              marginRight: "20px",
            }}
          >
            <small className="text-center">{props.granularity}</small>
          </p>
          <dt style={{ marginTop: "20px", marginLeft: "15px" }}>
            <img
              src={props.assetCDN + "/images/geographic-coverage.png"}
              style={{ height: "60px", marginRight: "10px" }}
          />
            <strong style={{ fontSize: "20px" }}>Geographic Coverage</strong>
          </dt>
          <p
              style={{
                marginTop: "20px",
                marginLeft: "30px",
                overflowWrap: "anywhere",
                marginRight: "20px",
              }}
            >
              <small>{props.geographicCoverage}</small>
          </p>
          <dt style={{ marginTop: "20px", marginLeft: "30px" }}>
            {/*<img
              src={props.assetCDN + "/images/updated-01.png"}
              style={{ height: "40px", marginRight: "10px" }}
          />*/}
            <strong style={{ fontSize: "20px" }}>Dataset Type</strong>
          </dt>
          <p
              style={{
                marginTop: "20px",
                marginLeft: "30px",
                overflowWrap: "anywhere",
                marginRight: "20px",
              }}
            >
              <small>{props.datasetType}</small>
          </p>
          <dt style={{ marginTop: "20px", marginLeft: "30px" }}>
            <img
              src={props.assetCDN + "/images/link-01.png"}
              style={{ height: "40px", marginRight: "10px" }}
            />
            <strong style={{ fontSize: "20px" }}>Linking Attributes</strong>
          </dt>
          <p
            style={{
              marginTop: "20px",
              marginLeft: "30px",
              overflowWrap: "anywhere",
              marginRight: "20px",
            }}
          >
            <small className="text-center">{props.linkingAttrs}</small>
          </p>
          <dt style={{ marginTop: "20px", marginLeft: "30px" }}>
            <img
              src={props.assetCDN + "/images/calendar-icon-01.png"}
              style={{ height: "40px", marginRight: "10px" }}
            />
            <strong style={{ fontSize: "20px" }}>Coverage Years</strong>
          </dt>
          <p
            style={{
              marginTop: "20px",
              marginLeft: "30px",
              overflowWrap: "anywhere",
              marginRight: "20px",
            }}
          >
            <small className="text-center">{props.coverageYears}</small>
          </p>
          <dt style={{ marginTop: "20px", marginLeft: "15px" }}>
            <img
              src={props.assetCDN + "/images/update-frequency.png"}
              style={{ height: "60px", marginRight: "10px" }}
            />
            <strong style={{ fontSize: "20px" }}>Update Frequency</strong>
            <p
              style={{
                marginTop: "20px",
                marginLeft: "30px",
                overflowWrap: "anywhere",
                marginRight: "20px",
              }}
            >
              <small>{props.updateFrequency}</small>
            </p>
            </dt>
          <dt style={{ marginTop: "20px", marginLeft: "30px" }}>
            <img
              src={props.assetCDN + "/images/references-01.png"}
              style={{ height: "40px", marginRight: "10px" }}
            />
            <strong style={{ fontSize: "20px" }}>References</strong>
          </dt>
          <p
            style={{
              marginTop: "20px",
              marginLeft: "30px",
              overflowWrap: "anywhere",
              marginRight: "20px",
            }}
          >
            <small className="text-center">
              {props.links == "" ? (
                <span>Not available</span>
              ) : (
                linksAry.map((item, index) => {
                  return (
                    <span>
                      {item.includes(" - ") && (
                        <p>
                          {item.split(" - ")[0]} :
                          <a href={item.split(" - ")[1]} target="_blank">
                            {item.split(" - ")[1]}
                          </a>
                        </p>
                      )}

                      {!item.includes(" - ") && (
                        <a href={item.split(" - ")[0]} target="_blank">
                          {item.split(" - ")[0]}
                        </a>
                      )}
                    </span>
                  );
                })
              )}
            </small>
          </p>
          {/* <dt style={{ marginTop: "20px", marginLeft: "30px" }}>
            <img
              src={props.assetCDN + "/images/updated-01.png"}
              style={{ height: "40px", marginRight: "10px" }}
            />
            <strong style={{ fontSize: "20px" }}>Updated</strong>
            <p
              style={{
                marginTop: "20px",
                marginLeft: "30px",
                overflowWrap: "anywhere",
                marginRight: "20px",
              }}
            >
              <small>Yearly</small>
            </p>
            </dt>*/}
          <dt style={{ marginTop: "20px", marginLeft: "30px" }}>
            <img
              src={props.assetCDN + "/images/attributes-01.png"}
              style={{ height: "40px", marginRight: "10px" }}
            />
            <strong style={{ fontSize: "20px" }}>Attributes</strong>
          </dt>
          <ul
            style={{
              marginTop: "20px",
              marginLeft: "30px",
              overflowWrap: "anywhere",
              marginRight: "20px",
            }}
          >
            {props.dataAttributes.map((item, index) => (
              <li key={index} className="text-muted">
                <small>{item}</small>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default DetailNavigatorPanel;

DetailNavigatorPanel.propTypes = {
  // This is the Dataset Attributes that we show in the panel
  dataAttributes: PropTypes.array,
  granularity: PropTypes.string,
  coverageYears: PropTypes.string,
  linkingAttrs: PropTypes.string,
  geographicCoverage:PropTypes.string,
  datasetType:PropTypes.string,    
  updateFrequency:PropTypes.string,
};

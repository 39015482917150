import React from "react";
import { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import MainNavBar from "../layout/MainNavBar";
import BottomNavBar from "../layout/BottomNavBar";
import { ApolloProvider } from "@apollo/react-hooks";
import Logout from "./logout";
import axios from "axios";
import jwt_decode from "jwt-decode";

import { client } from "../configs/apolloConfig";

import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
} from "react-router-dom";

import HomeLayout from "../layout/HomeLayout";
import HomeLayoutV1 from "../layout/HomeLayoutV1";
import DetailLayout from "../layout/DetailLayout";

function Home() {
  const [showBackBtn, setShowBackBtn] = useState(false);
  const [selectedFilterId, setSelectedFilterId] = useState("ALL");
  const [filterMode, setFilterMode] = useState("ALL");

  function onClickDataSourceFilter(event) {
    setSelectedFilterId(event.target.id);
    setFilterMode(event.target.id);
  }

  function onClickDirectDataSourceFilterLink(val) {
    setSelectedFilterId(val);
    setFilterMode(val);
  }

  function onLoadDataSourceFilter() {
    setSelectedFilterId("ALL");
    setFilterMode("ALL");
  }

  useEffect(() => {
    axios
      .get("/iadc/userinfo", {})
      .then((response) => {
        //localStorage.setItem("token", response.headers["authorization"]);
        // console.log("auth jwt:  " + response.headers["authorization"]);
        //console.log(response.data);
        //var x = this.parseJwt(response.headers["authorization"]);//function to decode the jwt
        //console.log("m1:");
        ///console.log(x);
        //this is to handle local login case
        client.writeData({
          data: { user: { __typename: "User", name: response.data } },
        });
        var decoded = jwt_decode(response.headers["authorization"]); //library to decode the jwt
        //console.log("m2:");
        //console.log(decoded);
        /* console.log(decoded);
      console.log("name = " + decoded["sub"]);
      console.log("roles = " + decoded["authority"][0]["authority"]);
      console.log("roles = " + decoded["authority"][1]["authority"]);
      console.log(decoded);*/
        client.writeData({
          data: { user: { __typename: "User", name: decoded["sub"] } },
        });
        //this.setState({ jwtToken: response.headers["authorization"] });
        //return response.headers["authorization"];
      })
      .catch((err) => {
        // this.setState({ data: err });
      });
  }, []);

  function handleDataSetClick() {
     setShowBackBtn(true);
    
  }

  function parseJwt(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  return (
    <Router>
      <ApolloProvider client={client}>
        <div className="repeatTop"></div>
        <MainNavBar assetCDN="/iadc" showBackBtn={showBackBtn} />
        <Logout></Logout>
        <Switch>
          <Route
            path="/iadc/catalog/browse"
            render={(props) => (
              <HomeLayoutV1
                {...props}
                handleDataSetClick={handleDataSetClick}
                assetCDN="/iadc"
                onClickDataSourceFilter={onClickDataSourceFilter}
                selectedFilterId={selectedFilterId}
                filterMode={filterMode}
                onLoadDataSourceFilter={onLoadDataSourceFilter}
              />
            )}
          />
          <Route
            path="/iadc/catalog/datasets/rds"
            render={(props) => (
              <HomeLayout
                {...props}
                handleDataSetClick={handleDataSetClick}
                onClickDataSourceFilter={onClickDataSourceFilter}
                selectedFilterId="REGENSTRIEF DATA RESOURCES"
                filterMode="REGENSTRIEF DATA RESOURCES"
                assetCDN="/iadc"
                onClickDirectDataSourceFilterLink={onClickDirectDataSourceFilterLink}
              />
            )}
          />
           <Route
            path="/iadc/catalog/datasets/cr"
            render={(props) => (
              <HomeLayout
                {...props}
                handleDataSetClick={handleDataSetClick}
                onClickDataSourceFilter={onClickDataSourceFilter}
                selectedFilterId="CURATED REGISTRIES"
                filterMode="CURATED REGISTRIES"
                assetCDN="/iadc"
                onClickDirectDataSourceFilterLink={onClickDirectDataSourceFilterLink}
              />
            )}
          />
           <Route
            path="/iadc/catalog/datasets/iadc"
            render={(props) => (
              <HomeLayout
                {...props}
                handleDataSetClick={handleDataSetClick}
                onClickDataSourceFilter={onClickDataSourceFilter}
                selectedFilterId="IADC"
                filterMode="IADC"
                assetCDN="/iadc"
                onClickDirectDataSourceFilterLink={onClickDirectDataSourceFilterLink}
              />
            )}
          />
           <Route
            path="/iadc/catalog/datasets/all"
            render={(props) => (
              <HomeLayout
                {...props}
                handleDataSetClick={handleDataSetClick}
                onClickDataSourceFilter={onClickDataSourceFilter}
                selectedFilterId="ALL"
                filterMode="ALL"
                assetCDN="/iadc"
                onClickDirectDataSourceFilterLink={onClickDirectDataSourceFilterLink}
              />
            )}
          />

          <Route
            path="/iadc/catalog/showDataset/:filterMode/:id"
            render={(props) => (
              <DetailLayout
                {...props}
                assetCDN="/iadc"
                onClickDataSourceFilter={onClickDataSourceFilter}
                selectedFilterId={selectedFilterId}
                filterMode={filterMode}
              />
            )}
          />
          <Route
            path="/iadc/catalog/showDataset"
            render={(props) => <DetailLayout {...props} assetCDN="/iadc" />}
          />
          <Redirect from="/iadc/catalog" to="/iadc/catalog/browse" />
        </Switch>
        <div className="footerDiv">
          <BottomNavBar assetCDN="/iadc" />
        </div>
      </ApolloProvider>
    </Router>
  );
}

export default Home;

ReactDOM.render(<Home />, document.getElementById("root"));

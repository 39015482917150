import React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { faFolderOpen } from "@fortawesome/free-solid-svg-icons";

function DataRow(props) {
  const [hover, setHover] = useState(false);

  function onRowHover() {
    setHover(true);
  }

  function toggleHover(isToggled, target) {
    // Trigger callback on props
    //this.props.apClient.writeData({ data: {infoPanel: { __typename: "InfoPanel", title: "", content : this.props.title } }})
    //console.log(target)
    props.onRowClick(isToggled);
    setHover(isToggled);
  }

  // Activate row hover color
  var hoverStyle = hover
    ? { backgroundColor: "#e9ecef" }
    : { backgroundColor: "" };

  return (
    <div
      className="media text-muted pt-3"
      onMouseEnter={target => toggleHover(true, target)}
      onMouseLeave={target => toggleHover(false, target)}
    >
      <img
        data-src="holder.js/32x32?theme=thumb&bg=e83e8c&fg=e83e8c&size=1"
        alt=""
        className="mr-2 rounded"
      />
      <p
        className="media-body pb-3 mb-0 small lh-125 border-bottom border-gray"
        style={hoverStyle}
      >
        <Link
          className="d-block lead text-dark"
          to={{
            pathname: "showDataset/" + props.filterMode + "/" + props.rowId,
            state: {
              requestedDataset: props.resourceId,
              id: props.rowId,
              filterMode: props.filterMode
            }
          }}
          onClick={props.handleDataSetClick}
        >
          {props.title}{" "}
          <FontAwesomeIcon icon={faFolderOpen} size="lg" pull="right" />
        </Link>
        {props.description}
      </p>
    </div>
  );
}
export default DataRow;

DataRow.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  resourceId: PropTypes.string
};

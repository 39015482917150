import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { IS_USER_LOGGED_IN } from "../configs/queryConfig";
import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";

function Logout() {
  const { loading, error, data } = useQuery(IS_USER_LOGGED_IN);
  return (
    <div>
      {!data.isLoggedIn && !loading && (
        <Router forceRefresh={true}>
          <Switch>
            <Redirect exact from="*" to="/iadc/login" key="from-root" />
          </Switch>
        </Router>
      )}
    </div>
  );
}

export default Logout;

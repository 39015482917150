import React from "react";

function BottomNavBar(props) {
  return (
    <div style={{ backgroundColor: "white" }}>
      <div className="repeatBottom"></div>
      <nav
        className="navbar navbar-expand-md navbar-light "
        style={{ backgroundColor: "white", height: "90px" }}
      >
        <a className="navbar-brand" href="/iadc/catalog/browse">
          <img
            src={props.assetCDN + "/images/regenstrief-logo-footer.png"}
            style={{ height: "55px" }}
          />
        </a>
        <p style={{ fontSize: "12px", paddingTop: "10px" }}>
          ©2020 Regenstrief Institute, Inc. All rights reserved. 1101 West Tenth
          Street • Indianapolis, IN 46202
          <br />
          <a href="https://www.regenstrief.org/privacy-policy/" target="_blank">
            Privacy Policy
          </a>
        </p>
        {/*
        <div
          style={{
            display: "inline-flex",
            width: "100%",
            justifyContent: "flex-end",
          }}
        >
          <img
            src={props.assetCDN + "/images/POLIS-Center_Logo.png"}
            style={{ height: "60px" }}
          />

          <img
            src={props.assetCDN + "/images/IndianaMPH_Logo.png"}
            style={{ height: "60px" }}
          />

          <img
            src={props.assetCDN + "/images/IHIE_Logo.png"}
            style={{ height: "60px" }}
          />

          <img
            src={props.assetCDN + "/images/AHA_Logo.png"}
            style={{ height: "60px" }}
          />
        </div>
        */}
      </nav>
    </div>
  );
}

export default BottomNavBar;

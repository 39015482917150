import React from "react";
import { useQuery } from "@apollo/react-hooks";
import "./infoPanelStyle.scss";
import { GET_INFOPANEL } from "../../apps/configs/queryConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faHistory,
  faLink,
} from "@fortawesome/free-solid-svg-icons";
// Query local state for info panel updates

/**
 * Displays the InfoPanel on the main page. It looks in the Apollo cache for the following variables:
 * - content - General free text content that you want to use
 * - highlights - A list of items that will be presented in a bullet list
 * <p className="media-body pt-3 mb-0 small lh-125">{loading ? '' : data.infoPanel.content}</p>
 */
function InfoPanel() {
  const { loading, error, data, client } = useQuery(GET_INFOPANEL);

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  return (
    <div className="stickyInfoPanel">
      <div className="infoPanelheader">
        <p>
          <strong>Dataset Highlights</strong>
        </p>
      </div>
      <div className="infoPanelcontent">
        <div>
          <dt>
            <FontAwesomeIcon icon={faInfoCircle} size="lg" pull="left" />
            Granularity
          </dt>
          <p>
            <small className="text-center">{data.infoPanel.granularity}</small>
          </p>
          <dt>
            <FontAwesomeIcon icon={faLink} size="lg" pull="left" />
            Linking Attributes
          </dt>
          <p>
            <small className="text-center">{data.infoPanel.linkingAttrs}</small>
          </p>
          <dt>
            <FontAwesomeIcon icon={faHistory} size="lg" pull="left" />
            Coverage Years
          </dt>
          <p>
            <small className="text-center">
              {data.infoPanel.coverageYears}
            </small>
          </p>
          <dt>
            <FontAwesomeIcon icon={faLink} size="lg" pull="left" />
            References
          </dt>
          <p>
            <small className="text-center">
              {data.infoPanel.links == "" ? (
                <span>Not available</span>
              ) : (
                <a href={data.infoPanel.links} target="_blank">
                  {data.infoPanel.links}
                </a>
              )}
            </small>
          </p>
          {/*<dt>
            <FontAwesomeIcon icon={faHistory} size="lg" pull="left" />
            Updated: Yearly
          </dt>*/}
        </div>
      </div>
    </div>
  );
}

export default InfoPanel;
